import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import doAPI from "../api";

// Async thunk for fetching coach availability
export const fetchCoachAvailability = createAsyncThunk(
  "availability/fetchCoachAvailability",
  async ({ date, start_time, end_time }, { rejectWithValue }) => {
    //  console.log(date, start_time, end_time)
    try {
      const response = await doAPI.getData(`schedule/coach-availability?date=${date}&start_time=${start_time}&end_time=${end_time}`);
      //  console.log(response)
      return response?.data?.data; // Assuming response contains the data directly
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Slice definition
const availabilitySlice = createSlice({
  name: "availability",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetAvailabilityState: state => {
      state.data = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCoachAvailability.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCoachAvailability.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCoachAvailability.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetAvailabilityState } = availabilitySlice.actions;

export default availabilitySlice.reducer;
