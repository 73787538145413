// usersCoachesSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Service from "../../http"; // Update this import path to the correct location of your http.js file

const initialState = {
  coach: [],
  totalPageCount: 0,
  loading: false,
  error: null,
};

export const fetchCoaches = createAsyncThunk("users/fetchCoaches", async ({ centerId, sportId, page_no }, thunkAPI) => {
  try {
    const service = new Service();
    const queryString = `coach/listview?center_id=${centerId}&sport_id=${sportId}&page_no=${page_no}`;
    const response = await service.get(queryString);

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchCoachRoles = createAsyncThunk("users/fetchCoachRoles", async thunkAPI => {
  try {
    const service = new Service();
    const queryString = "coach/coach-role"; // The endpoint for fetching coach roles
    const response = await service.get(queryString);

    return response.data; // Assuming the API returns the necessary data in the response
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const assignCoachRole = createAsyncThunk("users/assignCoachRole", async ({ coach_id, coach_role }, thunkAPI) => {
  try {
    const service = new Service();
    const queryString = "coach/assign-coach-role"; // The endpoint for assigning a coach role
    const body = { coach_id, coach_role }; // Constructing the request body
    const response = await service.post(queryString, body); // Sending a POST request

    return response.data; // Assuming the API returns the necessary data in the response
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const usersCoachesSlice = createSlice({
  name: "coaches",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCoaches.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCoaches.fulfilled, (state, action) => {
        state.loading = false;
        state.coach = action.payload?.content;
        state.totalPageCount = action.payload?.totalPages;
      })
      .addCase(fetchCoaches.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCoachRoles.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCoachRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.coachRoles = action.payload; // Store the coach roles in the state
      })
      .addCase(fetchCoachRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(assignCoachRole.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(assignCoachRole.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally handle the response after assigning the coach role (e.g., updating local state)
      })
      .addCase(assignCoachRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default usersCoachesSlice.reducer;
